<!--
 * @Descripttion: 
 * @version: 
 * @Author: xyh
 * @Date: 2021-04-27 11:11:56
 * @LastEditTime: 2021-09-01 14:51:42
-->
<template>
  <div class="settlement_detail">
    <a-modal
      title=""
      :visible="wxchatTipsFlag"
      :footer="null"
      @cancel="wxchatTipsFlag = false"
      width="421px"
      :mask="false"
      centered
      :maskClosable="false"
    >
      <div class="modaltips_container">
        <div class="modal_tips">
          <div class="img">
            <img src="../../assets/ok_icon.png" alt="" />
          </div>
          <div class="modal_tips_test">
            <div>自由职业者任务结果已全部通过，</div>
            <div>请提交付款审核。</div>
			<div v-if="userRoleType=='102011'" style="font-weight: 800;">备注：提交后需要联系管理员审核</div>
          </div>
        </div>
        <div class="btn_container">
          <a-button @click="wxchatTipsFlag = false">取消</a-button>
          <a-button type="primary" @click="queryAlipay" style="margin-left: 12px"
            >提交付款审核</a-button
          >
        </div>
      </div>
    </a-modal>

    <div class="detail_head">
      <!-- <span class="icon_left"><a-icon type="left" /></span> -->
      <span class="text1" @click="goTaskManage">任务管理 /</span>
      <span class="text1" @click="goback"> 任务详情 /</span>
      <span class="text2"> 结算单详情</span>
    </div>
    <div class="detail_title">
      <div class="title">结算单</div>
    </div>
    <div class="detail_tips">
      <div class="tips_container">
        <div class="icon">
          <img src="../../assets/tips.png" alt="" class="img" />
        </div>
        <div class="text">
          <div>
            1、请您严格审核自由职业者上传的服务成果，成果必须与发布的任务相关且真实有效，服务成果须一目了然的反映出自由职业者从事的业务内容及完成情况，服务成果包括但不限于：服务人员本人在服务场景中的照片（能够看到产品或企业logo）、设计效果图、服务的方案/计划书、绩效费发放规则、公司系统中的销售及提佣数据、专业技术服务对应的服务成果等(支持doc、docx、xls、xlsx、pdf、jpg等格式)，以上成果证据均需企业盖章。
          </div>
          <div>
            2、如服务成果不能提供客观图像资料，可能无法通过平台开具发票。
          </div>
          
        </div>
      </div>
    </div>

    <div class="detail_search">
      <div class="search_container">
        <div class="search_item">
          <span>用户姓名：</span>
          <a-input
            style="width: 250px"
            v-model="queryParam.userName"
            placeholder="请输入姓名"
          />
        </div>
        <div class="search_item">
          <span class="span">付款状态：</span>
          <a-select
            style="width: 250px"
            placeholder="请选择状态"
            v-model="queryParam.status"
          >
            <a-select-option value="10">待上传</a-select-option>
            <a-select-option value="11">已上传</a-select-option>
            <a-select-option value="20">待支付</a-select-option>
            <a-select-option value="30">支付处理中</a-select-option>
            <!-- <a-select-option value="12">待确认</a-select-option> -->
            <a-select-option value="40">支付成功</a-select-option>
            <!-- <a-select-option value="50">已取消</a-select-option> -->
            <!-- <a-select-option value="51">已拒绝</a-select-option> -->
          </a-select>
        </div>
        <div class="all_btn">
          <a-button type="primary" @click="checkPayDetailTable">查询</a-button>
          <a-button
            style="margin-left: 12px"
            icon="reload"
            @click="resetPayDetailTable"
            >重置</a-button
          >
        </div>
      </div>
    </div>

    <div class="detail_pay">
      <div class="pay_container">
        <a-button
          type="primary"
          @click="queryAlipay"
          :disabled="submitPayBtnDisable"
          >提交付款审核</a-button
        >
        <span class="desc desc1"
          >任务总额：<span class="amount">{{ taskTotalAmount }}</span></span
        >
        <span class="desc1"
          >付款人数：<span class="amount">{{ taskTotalPeople }}</span></span
        >
      </div>
      <div class="all_PL_upload">
        <a-button
          type="link"
          @click="uploadResultPL"
          v-if="
            raskDetailInfo.resultProvide == '20' &&
            payDetailInfo.payStatus == '50'
          "
          >批量上传</a-button
        >
      </div>
    </div>

    <div class="detail_tips">
      <img src="../../assets/tips.png" alt="" /><span>{{
        mainPayStatus[payDetailInfo.payStatus]
      }}。</span>
	  <br />
	  <span v-if="userRoleType=='102011' && payDetailInfo.payStatus=='40'" style="font-weight: 800;">备注：提交后需要联系管理员审核</span>
    </div>
	

    <div class="detail_tab">
      <s-table
        style="border-top: 1px solid #f0f0f0; margin-top: 16px"
        ref="rwshTable"
        :columns="columnsWkjsd"
        :data="dataWkjsd"
        :rowKey="(record) => record.id"
      >
        <!-- <span slot="cycle" slot-scope="text, record">
              <span>{{record.payPeriodStartTime}} ~ {{record.payPeriodEndTime}}</span>
            </span> -->
        <!-- <span slot="action" slot-scope="text, record" v-if="raskDetailInfo.status!=='50'">
              <a @click="pass(record)" v-if="record.auditStatus==='10'">通过</a>
              <a-divider type="vertical" v-if="record.auditStatus==='10'" />
              <a @click="noPass(record)" v-if="record.auditStatus==='10'">驳回</a>
            </span> -->
        <span slot="action" slot-scope="text, record">
          <a @click="cancelPays(record)" v-if="record.payStatus === '10'"
            >取消付款</a
          >
          <a-divider
            type="vertical"
            v-if="
              record.payStatus === '10' &&
              raskDetailInfo.resultProvide &&
              raskDetailInfo.resultProvide === '20'
            "
          />
          <a
            @click="uploadResult(record)"
            v-if="
              record.payStatus === '10' &&
              raskDetailInfo.resultProvide &&
              raskDetailInfo.resultProvide === '20'
            "
            >上传结果</a
          >
          <a @click="pass(record)" v-if="record.payStatus === '11'">通过</a>
          <a-divider type="vertical" v-if="record.payStatus === '11'" />
          <a @click="noPass(record)" v-if="record.payStatus === '11'">驳回</a>
        </span>
        <div slot="items" slot-scope="text, record">
          <a @click="showPic(record)" v-if="record.payStatus > '10'">查看</a>

          <a-modal
            title="任务结果查看"
            :visible="visiblePicture"
            :footer="null"
            @cancel="visiblePicture = false"
          >
            <ul class="pic-select">
              <li v-for="(item, index) in picList" :key="index">
                <a :href="item.fileUrl" target="_blank">{{
                  item.fileName === null
                    ? `自由职业者任务成果${index + 1}`
                    : item.fileName
                }}</a>
              </li>
              <li>任务结果描述：{{ currentContent }}</li>
            </ul>
          </a-modal>
          <a-modal
            title="驳回原因"
            :visible="visibleBh"
            @ok="handleSubmit"
            @cancel="handleCancelBh"
          >
            <a-form
              :form="formBh"
              :label-col="{ span: 5 }"
              :wrapper-col="{ span: 12 }"
              @submit="handleSubmit"
            >
              <a-form-item label="驳回原因">
                <a-textarea
                  :autoSize="{ minRows: 3, maxRows: 5 }"
                  :maxLength="100"
                  v-decorator="[
                    'errorInfo',
                    { rules: [{ required: true, message: '请输入驳回原因!' }] },
                  ]"
                />
              </a-form-item>
            </a-form>
          </a-modal>
        </div>
      </s-table>
    </div>

    <a-modal
      title="上传任务结果"
      :confirmLoading="confirmLoadingUpload"
      width="600px"
      destroyOnClose
      :visible="visibleExamineModal"
      @ok="handleRechargeModalOk"
      @cancel="handleExamineModalCancel"
    >
      <a-form :form="examinForm">
        <a-row class="form-row">
          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item
              label="任务结果"
              :labelCol="{ lg: { span: 4 }, sm: { span: 5 } }"
              :wrapperCol="{ lg: { span: 20 }, sm: { span: 19 } }"
            >
              <a-upload
                :action="url.frontUrl"
                :multiple="true"
                :headers="headers"
                :file-list="fileList"
                :data="uploadData"
                :beforeUpload="beforeUpload"
                @change="handleChange"
              >
                <a-button> <a-icon type="upload" /> 上传 </a-button>
              </a-upload>
            </a-form-item>
          </a-col>
          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item
              label="备注"
              :labelCol="{ lg: { span: 4 }, sm: { span: 5 } }"
              :wrapperCol="{ lg: { span: 20 }, sm: { span: 19 } }"
            >
              <a-textarea
                placeholder="请填写备注"
                v-decorator="[
                  'content',
                  {
                    rules: [
                      {
                        required: false,
                        message: '请填写备注',
                        whitespace: true,
                      },
                    ],
                  },
                ]"
              ></a-textarea>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>

    <a-modal
      title="请选择打款通道"
      :visible="visibleChoosePayType"
      @ok="submitPay"
      @cancel="visibleChoosePayType = false"
    >
      <div>
        <a-radio-group v-model="payTypeMode" @change="onChange">
          <a-radio value="1"> 银行卡 </a-radio>
          <a-radio value="2"> 支付宝 </a-radio>
          
        </a-radio-group>
      </div>
    </a-modal>
  </div>
</template>

<script>
import STable from "@/components/table/";
import moment from "moment";
import { ACCESS_TOKEN } from "@/store/mutation-types";
import Vue from "vue";
import {
  raskDetail,
  raskCancel,
  raskEnd,
  getWorkList,
  raskAssign,
  refuseAssign,
  cancelAssign,
  getRaskTypeName,
  taskWorkerPayItem,
  settlementStatistical,
  getPayDetails,
  taskWorkerPaySubmit,
  taskWorkerResult,
  getByWorkerIds,
  getUserByPhone,
  invitation,
  sendInvitationSms,
  invitationRecommendUser,
  getServiceRule,
  addPeriodPay,
  getOrderList,
  taskWorkerPayItemList,
  cancelPay,
  submitPeriodPay,
  fulFilTask,
  enterpriseAdd,
  enterpriseUnifyAdd,
  queryImportWorkerList,
  auditByPayItemId,
  getResultNew,
  queryAlipay,
} from "@/api/api";
export default {
  components: {
    STable,
  },
  data() {
    return {
      queryParam: {
        userName: "",
        status: undefined,
      },
      columnsDsh: [
        {
          title: "提交时间",
          dataIndex: "createTime",
          defaultSortOrder: "descend",
          sorter: (a, b) =>
            moment(a.createTime).unix() - moment(b.createTime).unix(),
          width: "120px",
        },
        {
          title: "用工周期",
          dataIndex: "cycle",
          width: "200px",
          scopedSlots: { customRender: "cycle" },
        },
        {
          title: "姓名",
          dataIndex: "userName",
          customRender: (text) => (text === null ? "--" : text),
          width: "80px",
        },
        {
          title: "联系电话",
          dataIndex: "phoneNo",
          customRender: (text) => (text === null ? "--" : text),
          width: "100px",
        },
        {
          title: "任务结果",
          dataIndex: "items",
          scopedSlots: { customRender: "items" },
          width: "100px",
        },
        {
          title: "状态",
          dataIndex: "auditStatus",
          customRender: (text) =>
            text === "10"
              ? "企业待审核"
              : text === "20"
              ? "已通过"
              : text === "11"
              ? "平台待审核"
              : "已驳回",
          width: "100px",
        },
        {
          title: "驳回原因",
          dataIndex: "errorInfo",
          customRender: (text) => (text === null ? "--" : text),
          width: "100px",
        },
        {
          title: "处理时间",
          dataIndex: "auditTime",
          width: "120px",
        },
        {
          title: "操作",
          dataIndex: "action",
          scopedSlots: { customRender: "action" },
          width: "130px",
          fixed: "right",
        },
      ], //任务审核
      payId: "",
      taskId: "",
      raskDetailInfo: {}, //任务详情
      canCancel: false,
      picList: [],
      currentContent: "",
      visiblePicture: false, //查看图片
      visibleBh: false, //驳回
      formBh: this.$form.createForm(this),
      examinForm: this.$form.createForm(this),
      record: {},
	  userRoleType:"",
      columnsWkjsd: [
        {
          title: "姓名",
          dataIndex: "userName",
        },
        {
          title: "联系电话",
          dataIndex: "phoneNo",
        },
        {
          title: "支付金额",
          dataIndex: "payAmount",
          customRender: (text) => text === null ? "--" : this.toDecimal2(text),
        },
        {
          title: "状态",
          dataIndex: "payStatus",
          customRender: (text) => this.pageTabPayStatus[text],
        },
        {
          title: "任务结果",
          dataIndex: "items",
          scopedSlots: { customRender: "items" },
        },
        {
          title: "操作",
          dataIndex: "action",
          scopedSlots: { customRender: "action" },
        },
      ], //尾款结算单
      disabledBtn2: false,
      confirmLoadingUpload: false,
      visibleExamineModal: false, //上传任务
      url: {
        frontUrl: window._CONFIG["domianURL"] + "/api/tax/common/file/upload",
      },
      uploadData: {
        fileType: "103206",
      },
      headers: { "X-Access-Token": Vue.ls.get(ACCESS_TOKEN) },
      fileList: [],
      picList: [],
      submitPayBtnDisable: false,
      taskTotalAmount: 0,
      taskTotalPeople: "0",
      isPl: false, //批量上传标识
      wxchatTipsFlag: false,
      payDetailInfo: {},
      mainPayStatus: {
        10: "结算单已提交财务付款审核",
        20: "结算单已提交财务付款审核",
        30: "结算单已付款成功",
        40: "任务结果处理完成，请提交财务付款审核",
        50: "等待上传服务成果并审核通过",
        60: "取消支付",
      },
      pageTabPayStatus: {
        10: "待上传",
        11: "已上传",
        12: "待确认",
        13: "平台待审核",
        20: "待支付",
        30: "支付处理中",
        40: "支付成功",
        50: "取消支付",
        51: "已拒绝",
        60: "支付失败",
      },
      tabList: [],

      visibleChoosePayType: false, //打款通道
      payTypeMode:'1',
    };
  },
  created() {
	this.userRoleType=Vue.ls.get('USER_ROLE_TYPE');
    this.payId = this.$route.params.id;
    this.taskId = window.localStorage.getItem("taskId");

    // console.log(this.taskId);
    // this.dataWkjsd()
    this.getRaskDetail();
    this.getSettlementSta();
  },
  mounted() {
    this.$destroyAll();
  },
  methods: {
    toDecimal2(x) {
        var f = parseFloat(x);
        if (isNaN(f)) {
          return '0.00';
        }

        var s = x.toString();
        var rs = s.indexOf(".");
        console.log("rs", rs);
        if (rs < 0) {
          rs = s.length;
          s += ".";
        } else {
          s = s.substr(0, s.indexOf(".") + 3);
        }
        while (s.length <= rs + 2) {
          s += "0";
        }

        return s;
      },
    
    //查询是否开通支付宝
    queryAlipay() {
      queryAlipay().then((res) => {
        if (res.success) {
          let resInfo = res.result;
          if (!resInfo) {
            //没有数据 未开通
            this.submitPay()
          } else {
            //有数据 判断状态
            if (resInfo.status == "100202") {
              //未开通
              this.submitPay()
            }
            if (resInfo.status == "100201") {
              //已开通
              this.visibleChoosePayType = true;
              //查询支付宝账户余额
              
            }
          }
        }
      });
    },
    getSettlementSta() {
      settlementStatistical({ payId: this.payId }).then((res) => {
        if (res.success && res.result) {
          let resp = res.result;
          this.taskTotalAmount = resp.payAmount
            ? this.toDecimal2(resp.payAmount)
            : "-";
          this.taskTotalPeople = resp.payNum ? resp.payNum : "-";
        }
      });
    },
    getPayDetails() {
      getPayDetails({ id: this.payId }).then((res) => {
        if (res.success) {
          this.payDetailInfo = res.result;
          if (this.payDetailInfo.payStatus == "40" && this.tabList.length > 0) {
            this.submitPayBtnDisable = false;
            this.wxchatTipsFlag = true;
          } else {
            this.submitPayBtnDisable = true;
          }
        }
      });
    },
    goTaskManage() {
      // window.localStorage.setItem('submenu','/rask')
      this.$router.push("/rask");
    },
    goback() {
      window.localStorage.setItem("submenu", "/rask");
      this.$router.back(-1);
    },
    // 获取尾款列表getWkList
    dataWkjsd(parameter) {
      return taskWorkerPayItemList(
        Object.assign({ payId: this.payId }, parameter, this.queryParam)
      ).then((res) => {
        // this.submitPayBtnDisable = res.result.data.length===0?false:true
        this.tabList = res.result.data;
        let list = res.result.data;
        let amount = 0;
        if (list && list.length > 0) {
          // this.taskTotalPeople = res.result.totalCount;
          // list.forEach(item=>{
          //   amount += item.payAmount
          // })
          // this.taskTotalAmount = amount.toFixed(2)
          // list.forEach(item=>{
          //   if (item.payStatus!=='10') {
          //     this.disabledBtn2 =false
          //   }
          // })
          // let flag = list.every(item=>item.payStatus == '20')
          // if(flag){
          //   this.submitPayBtnDisable = false
          //   this.wxchatTipsFlag = true
          // }else{
          //   this.submitPayBtnDisable = true
          // }
        } else {
          this.submitPayBtnDisable = true;
        }
        return res.result;
      });
    },
    // 获取审核列表getShList

    dataDsh(parameter) {
      return getByWorkerIds(
        Object.assign({ taskId: this.payId }, parameter, this.queryParam)
      ).then((res) => {
        return res.result;
      });
    },
    // 获取任务详情
    getRaskDetail() {
      raskDetail({ id: this.taskId }).then((res) => {
        // console.log('任务详情',res)
        if (res.success) {
          this.raskDetailInfo = res.result;
          this.getPayDetails();
          if (
            this.raskDetailInfo.status === "20" ||
            this.raskDetailInfo.status === "30" ||
            this.raskDetailInfo.status === "50"
          ) {
            this.canCancel = false;
          }
        }
      });
    },
    // 查看图片
    showPic(record) {
      // this.currentContent = record.content
      // this.picList = record.items
      // this.visiblePicture=true

      //
      console.log(record);
      getResultNew(record.id).then((res) => {
        if (res.success) {
          this.picList = res.result.items;
          this.currentContent = res.result.content;
          this.visiblePicture = true;
        }
      });
    },
    //  通过
    pass(record) {
      this.$confirm({
        title: "确定通过?",
        // content: h => <div style="color:red;">Some descriptions</div>,
        onOk: () => {
          return new Promise((resolve, reject) => {
            auditByPayItemId({ id: record.id, auditStatus: "20" }).then(
              (res) => {
                if (res.success) {
                  this.$message.success(res.message);
                  this.getRaskDetail();
                  this.$refs.rwshTable.refresh(true);
                  resolve();
                } else {
                  this.$message.error(res.message);
                  reject();
                }
              }
            );
          }).catch(() => console.log("Oops errors!"));
        },
        onCancel() {},
      });
    },
    // 驳回
    noPass(record) {
      this.record = record;
      this.visibleBh = true;
    },
    handleSubmit(e) {
      e.preventDefault();
      this.formBh.validateFields((err, values) => {
        if (!err) {
          let params = Object.assign(values, {
            id: this.record.id,
            auditStatus: "30",
          });
          auditByPayItemId(params).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.$refs.rwshTable.refresh(true);
              this.handleCancelBh();
              this.getRaskDetail();
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    handleCancelBh() {
      this.visibleBh = false;
      this.formBh.resetFields();
    },
    //搜索条件查询
    checkPayDetailTable() {
      this.$refs.rwshTable.refresh(true);
      if (this.payDetailInfo.payStatus == "40" && this.tabList.length > 0) {
        this.submitPayBtnDisable = false;
      } else {
        this.submitPayBtnDisable = true;
      }
      // this.getRaskDetail()
    },
    //搜索条件重置
    resetPayDetailTable() {
      (this.queryParam = {
        userName: "",
        status: undefined,
      }),
        this.$refs.rwshTable.refresh(true);
      if (this.payDetailInfo.payStatus == "40" && this.tabList.length > 0) {
        this.submitPayBtnDisable = false;
      } else {
        this.submitPayBtnDisable = true;
      }
      // this.getRaskDetail()
    },
    // 提交结算
    submitPay() {
      
      
      let that = this;
      this.$confirm({
        title: "确定付款审核？",
        onOk() {
          return new Promise((resolve, reject) => {
            submitPeriodPay({ payId: that.payId, taskId: that.taskId,payMode:that.payTypeMode }).then(
              (res) => {
                if (res.success) {
                  that.$message.success(res.message);
                  that.$refs.rwshTable.refresh(true);
                  window.localStorage.setItem("submenu", "/settlementDetail");
                  window.localStorage.setItem(
                    "taskInfo",
                    JSON.stringify(res.result)
                  );
                  window.location.reload(true);
                  resolve();
                } else {
                  that.$message.error(res.message);
                  reject();
                }
              }
            );
          }).catch((err) => console.log("err",err));
        },
        onCancel() {
          // console.log('Cancel');
        },
      });
    },
    // 取消付款
    cancelPays(record) {
      this.$confirm({
        title: "确定取消付款？",
        onOk: () => {
          return new Promise((resolve, reject) => {
            cancelPay(record.id).then((res) => {
              if (res.success) {
                this.$message.success(res.message);
                this.$refs.rwshTable.refresh(true);
                this.getRaskDetail();
                // this.$refs.yfkTable.refresh(true)
                resolve();
              } else {
                this.$message.error(res.message);
                reject();
              }
            });
          }).catch(() => console.log("Oops errors!"));
        },
        onCancel() {
          // console.log('Cancel');
        },
      });
    },
    // 上传任务结果
    uploadResult(record) {
      this.isPl = false;
      this.uploadRecord = record;
      this.visibleExamineModal = true;
    },
    //批量上传任务结果
    uploadResultPL() {
      this.isPl = true;
      this.visibleExamineModal = true;
    },
    handleRechargeModalOk() {
      this.examinForm.validateFields((err, fieldsValue) => {
        if (err) {
          return;
        }
        if (this.fileList.length === 0) {
          this.$message.error("请上传任务结果！");
          return;
        }
        let isUpload = true;
        this.fileList.forEach((item) => {
          if (item.fileName === undefined) {
            item.status = "error";
            isUpload = false;
          }
        });
        if (!isUpload) {
          this.$message.error("上传文件中有错误文件，请删除后提交");
          return;
        }
        this.confirmLoadingUpload = true;
        const description = this.examinForm.getFieldsValue();
        if (this.isPl) {
          let params = {
            payId: this.payId,
            resultItems: this.fileList,
            content: description.content,
            taskId: this.taskId,
          };
          // console.log('批量上传',params);
          // return
          enterpriseUnifyAdd(Object.assign(params)).then((res) => {
            this.confirmLoadingUpload = false;
            if (res.success) {
              this.$message.success(res.message);
              this.$refs.rwshTable.refresh(true);
              // this.$refs.yfkTable.refresh(true);
              this.handleExamineModalCancel();
              this.visibleTaskDetail = false;
              this.getRaskDetail();
            } else {
              this.$message.error(res.message);
            }
          });
        } else {
          let params = {
            results: [
              {
                payItemId: this.uploadRecord.id,
                workerId: this.uploadRecord.workerId,
                resultItems: this.fileList,
                content: description.content,
              },
            ],
            taskId: this.uploadRecord.taskId,
          };
          enterpriseAdd(Object.assign(params)).then((res) => {
            this.confirmLoadingUpload = false;
            if (res.success) {
              this.$message.success(res.message);
              this.$refs.rwshTable.refresh(true);
              // this.$refs.yfkTable.refresh(true);
              this.handleExamineModalCancel();
              this.visibleTaskDetail = false;
              this.getRaskDetail();
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    handleExamineModalCancel() {
      this.examinForm.resetFields();
      this.fileList = [];
      this.confirmLoadingUpload = false;
      this.visibleExamineModal = false;
    },
    beforeUpload(file) {
      const isJpgOrPng = true;
      if (!isJpgOrPng) {
        this.$message.error("上传照片格式不正确!");
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 50;
      if (!isLt2M) {
        this.$message.error("上传文件大小不能超过50MB!");
        return false;
      }
      return isJpgOrPng && isLt2M;
    },
    handleChange(info) {
      let fileList = [...info.fileList];
      // 1. Limit the number of uploaded files
      fileList = fileList.slice(-9);
      // 2. read from response and show file link
      fileList = fileList.map((file) => {
        if (file.response) {
          // Component will show file.url as link
          file.url = "https://oss.zfycloud.com" + "/" + file.response.result;
          file.fileUrl =
            "https://oss.zfycloud.com" + "/" + file.response.result;
          file.fileName = file.name;
        }
        return file;
      });
      this.fileList = fileList;
    },
  },
};
</script>

<style lang="less">
.modaltips_container {
  .modal_tips {
    padding: 52px 50px 27px 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    padding-top: 20px;
    .img {
      width: 43px;
      height: 41px;
      margin-right: 10px;
    }
  }
  .btn_container {
    text-align: center;
  }
}
.pic-select {
  padding: 0;
  margin: 0;
  list-style: none;
  li {
    margin: 10px 0;
  }
}
.settlement_detail {
  .detail_head {
    font-size: 14px;
    color: #262626;
    height: 53px;
    line-height: 53px;
    border-bottom: 1px solid #e8e8e8;
    .icon_left {
      padding-right: 16px;
    }
    .text1 {
      color: #999999;
      cursor: pointer;
    }
    .text2 {
      cursor: pointer;
    }
  }
  .detail_title {
    font-size: 18px;
    color: #262626;
    margin: 27px 0 17px 0;
    .title {
      padding-left: 10px;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 2px;
        width: 2px;
        height: 20px;
        background: #1890ff;
      }
    }
  }
  .detail_tips {
    .tips_container {
      background: #fffbe6;
      display: flex;
      padding: 15px 15px 15px 5px;
      .icon {
        padding-right: 12px;
      }
      .text {
        font-size: 12px;
        color: #808080;
      }
    }
  }
  .detail_search {
    margin: 20px 0 24px 0;
    .search_container {
      display: flex;
      align-items: center;
      .search_item {
        .span {
          padding-left: 20px;
        }
      }
      .all_btn {
        padding-left: 40px;
      }
    }
  }
  .detail_pay {
    margin-bottom: 7px;
    display: flex;
    justify-content: space-between;
    .pay_container {
      .desc {
        padding: 0 50px 0 25px;
      }
      .desc1 {
        font-size: 16px;
        color: #666;
        .amount {
          color: #262626;
        }
      }
    }
  }
  .detail_tips {
    display: flex;
    align-items: center;
    color: #5c5c5c;
    font-size: 12px;
    span {
      padding-left: 3px;
    }
  }
}
</style>